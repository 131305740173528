<template>
  <div class="bg">
    <div class="top">
      <img src="../../assets/img/inquirying_1.png" alt="" />
      <span v-if="countdown != 0"
        >问诊中（剩余时间：<runtime :remainTime="countdown"></runtime>）</span
      >
      <span v-else>待接诊</span>
    </div>
    <!-- 药房 -->
    <img
      src="../../assets/img/inquirying_3.png"
      alt=""
      class="pharmacy"
      @click="gopharmacy"
    />
    <!-- 资料卡 -->
    <div class="Patientcard">
      <h4>预约时间</h4>
      <p class="mb24">12月1日 18：00——19：00</p>
      <h4>病情描述</h4>
      <p class="sl">
        最近一个月总是头晕、头痛、疲劳、心悸等，有时还会出现注意力不集中、记忆力减退、肢体麻木的现象
      </p>
      <div class="bot">
        <div>
          <span class="f14">王林</span>
          <span class="em">男 46岁</span>
        </div>
        <div>
          <span class="goPatientinfo">患者资料</span>
          <img src="../../assets/img/right.png" class="" alt="" />
        </div>
      </div>
    </div>
    <!-- 聊天 -->
    <div class="chatbox">
      <div class="topmsg">—— 问诊已开始，本次问诊时间科持续24小时 ——</div>
      <div class="msgbox">
        <div class="msglist" v-for="item in msglist" :key="item.userid">
          <div class="time">
            {{ item.time }}
          </div>
          <div class="you" v-if="item.userid != userid">
            <img :src="item.img" alt="" />
            <div class="msg">{{ item.content }}</div>
          </div>
          <div class="my" v-else>
            <div class="msg">{{ item.content }}</div>

            <img :src="item.img" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div>
      
          <send/>
    </div>
    <!-- 弹窗 -->
    <van-action-sheet v-model="show" :round="false" :close-on-click-overlay="false">
      <div class="actionbox" @click="startinquiry">
        <img src="../../assets/img/inquirying_2.png" alt="" />
        <span>接诊</span>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
import runtime from "@/components/remainTime";
import send from "@/components/message-send-box";

export default {
  data() {
    return {
      show: false,
      countdown: 0, //1800倒计时
      msglist: [
        {
          content:
            "康康的色康康的色康康的色康康的色康康的色康康的色康康的色康康的色康康的色康康的色康康的色康康的色康康的色康康的色康康的色康康的色康康的色康康的色",
          img:
            "https://oimagec8.ydstatic.com/image?id=-875241627009222359&product=adpublish&format=JPEG&w=520&h=347",
          userid: 1,
          time: "2020-08-08  21：00：32",
        },
        {
          content:
            "色色的康康康康色色的康康康康色色的康康康康色色的康康康康色色的康康康康",
          img:
            "https://oimagec8.ydstatic.com/image?id=-875241627009222359&product=adpublish&format=JPEG&w=520&h=347",
          userid: 2,
          time: "2020-08-08  21：00：32",
        },
      ],
      userid: 1,
    };
  },
  components: { runtime ,send},
  methods: {
    // 开始接诊
    startinquiry() {
      this.countdown = 1800;
      this.show = false;
    },
    // 药房
    gopharmacy() {},
  },
};
</script>
<style lang="less" scoped>
.chatbox {
  padding: 0 16px;
  .topmsg {
    color: #c2c2c2;
    font-size: 12px;
    margin-bottom: 16px;

  }
  .msglist {
    margin-bottom: 16px;
    .time {
      color: #c2c2c2;
    margin-bottom: 16px;

    }
    .you {
      display: flex;
      img {
        display: block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 8px;
      }
      .msg {
        max-width: 228px;
        padding: 16px;
        // height: 85px;
        background: #efefef;
        border-radius: 0px 12px 12px 12px;
        text-align: left;
        line-height: 18px;
        color: #444444;
      }
    }
    .my {
      display: flex;
      justify-content: flex-end;
      img {
        display: block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
      .msg {
        max-width: 228px;
        padding: 16px;
        // height: 85px;
        background: rgba(212,240,255,1);
        border-radius: 0px 12px 12px 12px;
        text-align: left;
        line-height: 18px;
        color: #444444;
        margin-right: 8px;
      }
    }
  }
}
.pharmacy {
  display: block;
  position: fixed;
  width: 70px;
  height: 70px;
  right: 0;
  top: 67px;
}
.actionbox {
  padding: 36px;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: block;
    margin: 0 auto;
    margin-bottom: 8px;
  }
  span {
    color: #1daefc;
    font-size: 20px;
    font-weight: 700;
  }
}
.bg {
  width: 375px;
  background: #ffffff;
  .Patientcard {
    text-align: left;
    width: 343px;
    height: 237px;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    margin: 16px;
    padding: 16px;
    color: #2d2d2d;
    .bot {
      border-top: 1px solid #d8d8d8;
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      // line-height: 24px;
      padding-top: 14px;
      .f14 {
        font-size: 14px;
        margin-right: 8px;
      }
      .em {
        font-size: 12px;
      }
      .goPatientinfo {
        color: rgba(29, 174, 252, 1);
        vertical-align: super;
        margin-right: 8px;
      }
      img {
        width: 10px;
        height: 24px;
        display: inline-block;
      }
    }
    h4 {
      font-weight: 700;
      color: #2d2d2d;
      margin-bottom: 8px;
    }
    .mb24 {
      margin-bottom: 24px;
    }
  }
  .top {
    height: 49px;
    background: #1daefc;
    text-align: left;

    color: #ffffff;
    padding: 16px 16px 12px 16px;
    img {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
    span {
      // line-height: 49px;
      vertical-align: top;
    }
  }
}
</style>
